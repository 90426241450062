import ar from './ar.json'
import bg from './bg.json'
import cs from './cs.json'
import da from './da.json'
import de from './de.json'
import el from './el.json'
import en from './en.json'
import es from './es.json'
import et from './et.json'
import fi from './fi.json'
import fr from './fr.json'
import he from './he.json'
import hi from './hi.json'
import hr from './hr.json'
import hu from './hu.json'
import id from './id.json'
import is from './is.json'
import it from './it.json'
import ja from './ja.json'
import ko from './ko.json'
import ms from './ms.json'
import nb from './nb.json'
import nl from './nl.json'
import pl from './pl.json'
import pt from './pt.json'
import ro from './ro.json'
import ru from './ru.json'
import sr from './sr.json'
import sv from './sv.json'
import th from './th.json'
import tr from './tr.json'
import uk from './uk.json'
import vi from './vi.json'
import sl from './sl.json'
import sk from './sk.json'
import lt from './lt.json'
import lv from './lv.json'
import az from './az.json'
import bs from './bs.json'
import lo from './lo.json'
import mk from './mk.json'
import sq from './sq.json'
import zhMO from './zh-MO.json'
import zhCN from './zh-CN.json'
import zhHK from './zh-HK.json'
import zhTW from './zh-TW.json'

export default {
  ar,
  bg,
  cs,
  da,
  de,
  el,
  en,
  es,
  et,
  fi,
  fr,
  he,
  hi,
  hr,
  hu,
  id,
  is,
  it,
  ja,
  ko,
  ms,
  nb,
  nl,
  pl,
  pt,
  ro,
  ru,
  sr,
  sv,
  th,
  tr,
  uk,
  vi,
  sl,
  sk,
  lt,
  lv,
  az,
  bs,
  lo,
  mk,
  sq,
  'zh-MO': zhMO,
  'zh-CN': zhCN,
  'zh-HK': zhHK,
  'zh-TW': zhTW
}
