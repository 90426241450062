import { ResponseOption } from '@focaldata/cin-ui-components'

import { EntryType } from './common'
import { LogicClauseProposition } from './questionLogic'

export interface RenderedQuestionnaire {
  surveyId: string
  questionnaire: Questionnaire
  quotaStatus: QuotaStatus
  isSurveyFull: boolean
  redirectToFDChat: boolean
}

export interface QuotaStatus {
  quotas: Quota[]
}

export interface Quota {
  key: QuotaKey
  isFull: boolean
}

export interface QuotaKey {
  questionId: string
  responseOptionId: string
}

export interface Questionnaire {
  questionnaireId: string
  entries: QuestionnaireEntry[]
  locale: {
    language: string
    country: string
  }
}

export interface QuotaResponseOption {
  responseOptionId: string
  questionId: string
}

export enum QuestionKind {
  AudienceKind = 'AudienceKind',
  QuestionnaireKind = 'QuestionnaireKind'
}

type LoopEntries = {
  id: string
  entryNumbers: number[]
  // determines which loops to pick, by loop basis
  priorityRank: number
  isQuotaFull: boolean
}

export interface LoopingConfig {
  sectionId: string
  maxIteration: number
  loopEntries: LoopEntries[]
  qualifyingLoopResponseOptionIds: string[]
}

export interface QuestionnaireEntry {
  entryNumber: number
  entryPosition: number
  renderedEntryPosition: number
  contextPosition: number
  renderedContextPosition: number
  entryType: EntryType
  entryItem: MatrixItem | QuestionItem | TextCardItem
  renderedForks?: RenderedForkTag[]
  questionKind: QuestionKind
  looping: LoopingConfig[] | null
}

export interface RenderedForkTag {
  forkName: string
  branchLabel: string
}

export type MatrixRowQualifyingOperator =
  | 'LessThanOrEquals'
  | 'GreaterThanOrEquals'

type MatrixRowQualifyingCondition = {
  numberOfQualifyingRows: number
  operator: MatrixRowQualifyingOperator
}

type MatrixQualification = {
  qualifyingOptions: string[]
  matrixRowQualifyingCondition: MatrixRowQualifyingCondition
}

export interface MatrixItem {
  matrixTitle: {
    title: string
    titleStyling?: string
    matrixTitleId: string
  }
  matrixRows: MatrixRow[]
  responseOptions: EntryResponseOption[]
  settingValues: EntrySettingValue[]
  questionLogic?: LogicClauseProposition[][]
  renderedForks?: RenderedForkTag[]
  questionMedia?: QuestionMedia
  matrixQualification?: MatrixQualification
}

export interface ScaleRange {
  min: number
  max: number
  step: number
  startingPosition: number
}
export interface QuestionScaleLabel {
  label: {
    text: string
  }
  position: number
}

export interface QuestionScale {
  range: ScaleRange
  labels: QuestionScaleLabel[]
}

export interface QuestionItem {
  questionTypeCode: string
  question: Question
  questionImageUrl?: string
  questionMedia?: QuestionMedia
  responseOptions?: EntryResponseOption[]
  settingValues?: EntrySettingValue[]
  questionScale?: QuestionScale
  questionLogic?: LogicClauseProposition[][]
  maxDiffSets?: MaxDiffSets
}

export interface MaxDiffSets {
  mostLabel: string
  leastLabel: string
  attributeLabel: string
  experimentalDesignVersion: number
  responseOptionSets: ResponseOptionSet[]
}

export interface ResponseOptionSet {
  setNumber: number
  position: number
  responseOptions: ResponseOption[]
}

export interface QuestionMedia {
  mediaUrl: string
  mediaType: MediaType
  renderedMediaUrl: string
}

export enum MediaType {
  Image = 'Image',
  Video = 'Video'
}
export interface TextCardItem {
  textCard: TextCard
  questionLogic?: LogicClauseProposition[][]
  questionMedia: QuestionMedia
  settingValues?: EntrySettingValue[]
}

export interface TextCard {
  textCardId: string
  title: string
  titleStyling?: string
  subtitle: string
  body: string
}

export interface MatrixRow {
  position: number
  renderedPosition: number
  question: Question
  maskingRules: MaskingRule[]
  questionMedia: QuestionMedia
}

export interface EntryResponseOption {
  position: number
  option: ResponseOption['option']
  renderedPosition: number
  route?: Route
  maskingRules: MaskingRule[]
}

export interface MaskingRule {
  clause: MaskingRuleClause
  maskingKeys: MaskingKey[]
  negated: boolean
}

export enum MaskingRuleClause {
  AND = 'AND',
  CONTAINS = 'CONTAINS',
  IS_ONE_OF = 'IS_ONE_OF',
  OR = 'OR'
}

export interface MaskingKey {
  questionId: string
  responseOptionId: string
  matrixTitleId?: string
}

export interface EntrySettingValue {
  code: SettingCodes
  value: SettingValues
}

export interface Route {
  targetNumber: number
}

export interface Question {
  questionId: string
  text: string
  titleStyling?: string
  textStyling?: string
}

export enum RoType {
  RoDouble,
  RoInt,
  RoLink,
  RoString
}

export enum QuestionTypes {
  FreeText = 'free_text',
  Ranked = 'ranked',
  Scale = 'scale',
  BasicChoice = 'basic',
  MaxDiff = 'max_diff',
  StandardAudience = 'standard_audience',
  CustomAudience = 'custom_audience',
  Matrix = 'matrix'
}

export enum SettingCodes {
  CharacterLimit = 'character_limit',
  ChoiceLimit = 'choice_limit',
  ChoiceIntervalMin = 'choice_interval_min',
  ChoiceIntervalMax = 'choice_interval_max',
  BasicChoice = 'basic_choice',
  ChoiceRequisite = 'choice_requisite',
  Routing = 'routing',
  Quotas = 'quotas',
  ContentStructure = 'content_structure',
  SliderLabels = 'slider_labels',
  HideSliderTracker = 'hide_slider_tracker',
  ScreeningQuestionResponseLimit = 'screening_question_response_limit',
  QuestionTimer = 'question_timer',
  CharacterMinimum = 'character_minimum',
  DefaultOptionNotApplicable = 'default_option_not_applicable',
  HardDisqualification = 'hard_disqualification',
  AutoAnswerAllSetting = 'auto_answer_all'
}

export enum SettingValues {
  Enabled = 'enabled',
  Disabled = 'disabled',
  SingleChoice = 'single_choice',
  MultipleChoice = 'multiple_choice',
  Numeric1 = 'numeric__^-?[0-9]\\d*(\\.\\d+)?$',
  Numeric2 = 'numeric_posint__^\\d+$',
  Numeric3 = 'numeric_int__^-?\\d+$',
  PostCode = 'postcode_uk__^[a-zA-Z]{1,2}\\d[a-zA-Z\\d]?\\s*\\d[a-zA-Z]{2}$',
  Alphanumeric = 'alphanumeric__^[A-Za-z0-9\\s]+$',
  Email = 'email__^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$',
  Unstructured = 'unstructured',
  PostCodeFull = 'postcode_uk__full',
  EightHundred = '800',
  Two = '2',
  Category = 'category'
}
