import { MaxDiff } from '@focaldata/cin-ui-components'
import {
  CfmContext,
  CfmData
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import { useQuestionTimer } from 'hooks/useQuestionTimer'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import { EntryType } from 'model/common'
import { QuestionItem, QuestionnaireEntry } from 'model/questionnaire'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { setHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import { ResponseOptionChoice } from 'model/responseChoice'
import { useQuestionnaireParams } from '../../hooks/useQuestionnaireParams'

interface Props {
  entry: QuestionnaireEntry
}

interface SetSelection {
  least?: number
  most?: number
}

const getResponseChoiceValue = (
  renderedPosition: number,
  setSelection: SetSelection
): 'Least' | 'Most' | 'NotSelected' => {
  if (renderedPosition === setSelection.least) return 'Least'
  if (renderedPosition === setSelection.most) return 'Most'
  return 'NotSelected'
}

const MaxDiffEntry: React.FC<Props> = ({ entry }: Props) => {
  const entryItem = entry.entryItem as QuestionItem
  const progress = useEntryProgress(entry)
  const timer = useQuestionTimer(entryItem)
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const sendResponseOptionChoices = useSendResponseChoice()
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const mandatoryParams = useQuestionnaireParams()

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: (
      <FormattedMessage
        id="question_maxDiffInstructions"
        defaultMessage="Select most and least preferred"
      />
    )
  }

  // TODO: this is the same for several types and should be extracted out
  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    setHiddenByDisplayLogic(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      entryItem.question.questionId
    )
    getNextEntryPosition()
  }

  const handleNext = (selections: SetSelection[]) => {
    if (!preview) {
      const responseChoices = selections.flatMap((setSelection, index) => {
        const currentSet = entryItem.maxDiffSets?.responseOptionSets.find(
          (set) => set.position === index
        )
        if (currentSet) {
          const responseChoices: ResponseOptionChoice[] =
            currentSet.responseOptions.map((ro) => {
              return {
                responseOptionId: ro.option.responseOptionId,
                position: ro.position,
                renderedPosition: ro.renderedPosition,
                value: getResponseChoiceValue(
                  ro.renderedPosition,
                  setSelection
                ),
                responseDatetime: renderingDateTime,
                setNumber: currentSet.setNumber
              }
            })
          return responseChoices
        }

        return []
      })
      sendResponseOptionChoices({
        questionId: entryItem.question.questionId,
        entry,
        entryType: EntryType.QuestionEntryType,
        questionTypeCode: entryItem.questionTypeCode,
        renderingDateTime,
        responseChoices,
        experimentalDesignVersion:
          entryItem.maxDiffSets?.experimentalDesignVersion
      })
    }
    getNextEntryPosition()
  }
  // has to take the maxdiffsets from the entryItem
  // and transform them so the maxdiff control can take them in
  // and then what it returns just set on the respondentChoice
  return (
    <MaxDiff
      item={entryItem}
      progress={progress}
      loc={loc}
      timer={timer}
      preview={preview}
      next={{ disabled: false, loading: false, onNext: handleNext }}
    />
  )
}

export default MaxDiffEntry
