import { SelectedSliderResponse, Slider } from '@focaldata/cin-ui-components'
import {
  CfmContext,
  CfmData
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import { useQuestionTimer } from 'hooks/useQuestionTimer'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import { ResponseChoiceValue } from 'model/common'
import {
  QuestionItem,
  QuestionnaireEntry,
  SettingCodes,
  SettingValues
} from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { getNowTimeString } from 'utils/datetime'
import { isSettingEnabled } from 'utils/question'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { useQuestionnaireParams } from '../../hooks/useQuestionnaireParams'

interface Props {
  entry: QuestionnaireEntry
}

const ScaleEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const progress = useEntryProgress(entry)
  const timer = useQuestionTimer(entryItem)
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const sendResponseOptionChoices = useSendResponseChoice()
  const mandatoryParams = useQuestionnaireParams()

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: (
      <FormattedMessage
        id="question_scaleChoiceInstructions"
        defaultMessage="Drag the button to rate"
      />
    )
  }

  const getResponseOptionChoices: (
    selectedSliderResponse: SelectedSliderResponse
  ) => ResponseOptionChoice = ({
    sliderOutput,
    timestamp,
    notApplicable,
    responseOptionId
  }) => {
    const value = sliderOutput ? sliderOutput.toString() : '0'
    // TODO: This implementation will need refactoring when multiple default
    // response options are supported
    return {
      value: notApplicable ? ResponseChoiceValue.Selected : value,
      responseDatetime: timestamp || getNowTimeString(),
      responseOptionId,
      position: notApplicable ? 0 : undefined,
      renderedPosition: notApplicable ? 0 : undefined
    }
  }

  const handleNext: (selectedSliderResponse: SelectedSliderResponse) => void = (
    selectedSliderResponse
  ) => {
    if (preview) {
      preview.onNavigateForward()
    } else {
      sendResponseOptionChoices({
        questionId: entryItem.question.questionId,
        entry,
        entryType: entry.entryType,
        questionTypeCode: entryItem.questionTypeCode,
        renderingDateTime,
        responseChoices: [getResponseOptionChoices(selectedSliderResponse)]
      })
      getNextEntryPosition()
    }
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    getNextEntryPosition()
  }

  const shouldShowLabels =
    entryItem.settingValues?.find(
      (settingValue) => settingValue.code === SettingCodes.SliderLabels
    )?.value === SettingValues.Enabled

  const shouldHideTracker = isSettingEnabled(
    entryItem.settingValues,
    SettingCodes.HideSliderTracker
  )

  return (
    <Slider
      shouldShowLabels={shouldShowLabels}
      key={entry.entryNumber}
      item={entryItem}
      loc={loc}
      preview={preview}
      progress={progress}
      timer={timer}
      shouldShowTracker={!shouldHideTracker}
      shouldShowValue={!shouldHideTracker}
      next={{ disabled: false, loading: false, onNext: handleNext }}
    />
  )
}

export default ScaleEntry
