import { useMount } from 'react-use'
import { FreeText, ResponseText } from '@focaldata/cin-ui-components'
import {
  CfmData,
  CfmContext
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import {
  QuestionItem,
  QuestionnaireEntry,
  QuestionTypes,
  SettingCodes,
  SettingValues
} from 'model/questionnaire'
import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import { ResponseOptionChoice } from 'model/responseChoice'
import { getNowTimeString } from 'utils/datetime'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { getSetting } from 'utils/question'
import { useQuestionTimer } from 'hooks/useQuestionTimer'
import { persistedFreeTextChoicesByQuestionId } from 'utils/persistedFreeTextChoicesByQuestionId'
import { useQuestionnaireParams } from '../../hooks/useQuestionnaireParams'
import {
  DEFAULT_LOCALE,
  Validation,
  getEmailValidation,
  getMinCharactersValidation,
  getNumberValidation,
  getPostcodeValidation,
  postCodeInstructionsByLocale
} from './FreeTextEntry.utils'

interface Props {
  entry: QuestionnaireEntry
}

const isCompactMap = new Map<SettingValues, boolean>([
  [SettingValues.Numeric1, true],
  [SettingValues.Numeric2, true],
  [SettingValues.Numeric3, true],
  [SettingValues.PostCode, true],
  [SettingValues.Email, true],
  [SettingValues.Unstructured, false],
  [SettingValues.Category, false]
])

const isNumericMap = new Map<SettingValues, boolean>([
  [SettingValues.Numeric1, true],
  [SettingValues.Numeric2, true],
  [SettingValues.Numeric3, true],
  [SettingValues.PostCode, false],
  [SettingValues.Email, false],
  [SettingValues.Unstructured, false],
  [SettingValues.Category, false]
])

const FreeTextEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem
  const { questionId } = entryItem.question
  const progress = useEntryProgress(entry)
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const sendResponseOptionChoices = useSendResponseChoice()
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const timer = useQuestionTimer(entryItem)
  const { formatMessage, locale } = useIntl()
  const mandatoryParams = useQuestionnaireParams()

  useMount(() => {
    persistedFreeTextChoicesByQuestionId.remove(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      questionId
    )
  })

  const type =
    getSetting(entryItem.settingValues, SettingCodes.ContentStructure) ||
    SettingValues.Unstructured
  const isPostCodeType =
    type === SettingValues.PostCode || type === SettingValues.PostCodeFull
  const typeKey = isPostCodeType ? SettingValues.PostCode : type
  const freeTextControlSettings = {
    isCompact: isCompactMap.get(typeKey),
    numericOnly: isNumericMap.get(typeKey)
  }
  const isFullPostalCodeType = type === SettingValues.PostCodeFull
  const shouldValidatePostCode =
    isPostCodeType && (locale === DEFAULT_LOCALE || locale === 'en-US')
  const localeKey =
    isFullPostalCodeType && locale === DEFAULT_LOCALE
      ? `${locale}_full`
      : locale
  const characterLimit =
    getSetting(entryItem.settingValues, SettingCodes.CharacterLimit) ||
    SettingValues.EightHundred
  const characterMinimum =
    getSetting(entryItem.settingValues, SettingCodes.CharacterMinimum) ||
    SettingValues.Two
  const minCharactersRequired =
    characterLimit < characterMinimum ? SettingValues.Two : characterMinimum

  const postCodeInstructionByLocale =
    postCodeInstructionsByLocale.get(localeKey) ||
    postCodeInstructionsByLocale.get(DEFAULT_LOCALE) ||
    ''

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: shouldValidatePostCode ? (
      postCodeInstructionByLocale
    ) : (
      <FormattedMessage
        id="question_freeTextInstructions"
        defaultMessage="Enter your answer in the box below"
      />
    ),
    typeYourAnswerHere: formatMessage({
      id: 'question_freeTextPlaceholder',
      defaultMessage: 'Type your answer here...'
    }),
    charactersUsed: formatMessage({
      id: 'question_freeTextCharactersUsed',
      defaultMessage: 'Characters used:'
    }),
    charactersUsedOutOf: formatMessage({
      id: 'question_freeTextCharactersUsedOutOf',
      defaultMessage: 'out of'
    })
  }

  const getResponseOptionChoices: (
    responseText: ResponseText
  ) => ResponseOptionChoice = (responseText) => {
    return {
      value: responseText.text,
      responseDatetime: responseText.timestamp || getNowTimeString()
    }
  }

  const handleNext: (selectedText: ResponseText) => void = (selectedText) => {
    const responseChoice = getResponseOptionChoices(selectedText)
    if (!preview) {
      sendResponseOptionChoices({
        questionId,
        entry,
        entryType: entry.entryType,
        questionTypeCode: QuestionTypes.FreeText,
        renderingDateTime,
        responseChoices: [responseChoice]
      })
    }
    persistedFreeTextChoicesByQuestionId.add(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      questionId,
      responseChoice.value
    )
    getNextEntryPosition()
  }
  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    getNextEntryPosition()
  }

  const getValidation = (): Validation | undefined => {
    switch (type) {
      case SettingValues.Email:
        return getEmailValidation()
      case SettingValues.Numeric1:
        return getNumberValidation()
      case SettingValues.Unstructured:
      case SettingValues.Category:
        return getMinCharactersValidation(minCharactersRequired)
      default:
        return shouldValidatePostCode
          ? getPostcodeValidation(localeKey)
          : undefined
    }
  }

  return (
    <FreeText
      item={entryItem}
      timer={timer}
      settings={freeTextControlSettings}
      validation={getValidation()}
      loc={loc}
      preview={preview}
      progress={progress}
      next={{ disabled: false, loading: false, onNext: handleNext }}
    />
  )
}

export default FreeTextEntry
